import request from '@/utils/request'

const userApi = {
  addCommissionCongfig: '/distributor/saveOrEditBrokerageConfig', // 2 添加-修改佣金基础配置信息
  addDisbutionConfig: '/distributor/saveOrEditBaseConfig',    // 1添加-修改分销基础配置信息
  /************************* 身份等级 ********************************/
  addDistributorLevel: '/distributor/saveOrEditLevelConfig',
  getLevelList: '/distributor/levelList',
  getLevelInfo: '/distributor/getDistributorLevelConfigById',
  delLevelInfo: '/distributor/delDistributorLevelConfigById',
  /************************** 身份等级 *******************************/

  getDistributeConfig: '/distributor/baseConfig',  // 获取分销配置详情
  getBroConfig: '/distributor/brokerageConfig',
  brokerageConfig: '/distributor/brokerageConfig', //佣金基础配置信息
  saveOrEditBrokerageConfig: '/distributor/saveOrEditBrokerageConfig', //添加-修改佣金基础配置信息
  getGoodsBrokerageConfig:'/distributor/getBrokerageConfigByGoodsId',
  saveOrEditGoodsBrokerageConfig:'/distributor/saveOrEditGoodsBrokerageConfig',
  /************************* 用户申请 身份等级 *****************************/
  listBydistributor: '/distributor/applyLevelList',
  applyTodistributor: '/distributor/applyLevel',
  checkApplication: '/distributor/auditApplyLevel',
  /************************* 用户申请 身份等级 *****************************/
}

export function addDisbutionConfig (data) {
  return request({
    url: userApi.addDisbutionConfig,
    method: 'post',
    data: data
  })
}
/******************************* 分销等级 *********************************/
// 添加 修改
export function addDistributorLevel (data) {
  return request({
    url: userApi.addDistributorLevel,
    method: 'post',
    data: data
  })
}
// 查询 列表
export function getLevelList (parameter) {
  return request({
    url: userApi.getLevelList,
    method: 'get',
    params: parameter
  })
}
// 查询 详情
export function getLevelInfo (parameter) {
  return request({
    url: userApi.getLevelInfo,
    method: 'get',
    params: parameter
  })
}
// 删除
export function delLevelInfo (parameter) {
  return request({
    url: userApi.delLevelInfo,
    method: 'get',
    params: parameter
  })
}
/******************************* 分销等级 *********************************/

// 2、添加 默认佣金配置项
export function addCommissionCongfig () {
  return request({
    url: userApi.addCommissionCongfig,
    method: 'post'
  })
}
// 分销配置信息
export function getDistributeConfig () {
  return request({
    url: userApi.getDistributeConfig,
    method: 'get'
  })
}

// 佣金配置信息
export function getBroConfig () {
  return request({
    url: userApi.getBroConfig,
    method: 'get'
  })
}

export function getBrokerageConfig(){
  return request({
    url: userApi.brokerageConfig,
    method: 'get'
  })
}


export function saveOrEditBrokerageConfig(data){
  return request({
    url: userApi.saveOrEditBrokerageConfig,
    method: 'POST',
    data: data
  })
}

export function  getGoodsBrokerageConfig(goodsId){
  return request({
    url: userApi.getGoodsBrokerageConfig+"?goodsId="+goodsId,
    method: 'get'
  })
}

export function saveOrEditGoodsBrokerageConfig(data){
  return request({
    url: userApi.saveOrEditGoodsBrokerageConfig,
    method: 'POST',
    data: data
  })
}

/******************************* 用户申请 身份等级 *********************************/
export function listBydistributor (data) {
  return request({
    url: userApi.listBydistributor,
    method: 'post',
    data: data
  })
}
export function applyTodistributor (data) {
  return request({
    url: userApi.applyTodistributor,
    method: 'post',
    data: data
  })
}
export function checkApplication (data) {
  return request({
    url: userApi.checkApplication,
    method: 'post',
    data: data
  })
}
/******************************* 用户申请 身份等级 *********************************/